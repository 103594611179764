import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { IDesignOrchestrationSlice } from 'interfaces/designOrchestration/designOrchestrationState.interface';
import { ISystemModule } from 'interfaces/designOrchestration/designOrchestrationModules.interface';
import { fetchModulesVersions } from 'api/manageModules/manageModulesThunks';
import { IModuleServer } from 'interfaces/modules/module.interface';

export const SLICE_KEY = 'designOrchestration';

const initialState: IDesignOrchestrationSlice = {
  systemModules: [],
  genericAndTenantModules: [],
  isAccordionOpen: true,
  orchestrationDetails: {
    orchestrationName: undefined,
    orchestrationDescription: undefined,
    businessProduct: undefined,
    throttleRate: undefined,
    cronTrigger: undefined,
    cronTimezone: undefined,
    sftpDropzone: undefined,
    sftpPrivateKey: undefined,
    ftpDropzone: undefined,
    s3Dropzone: undefined,
    notificationEmails: undefined,
    eventParamsMapping: undefined,
    reportingIndex: undefined,
    unpackProduct: undefined,
  },
  loading: false,
  xmlCode: '',
};

const designOrchestrationSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    clearState: (state) => {
      return initialState;
    },
    setOrchestrationDetails: (
      state,
      action: PayloadAction<
        Partial<IDesignOrchestrationSlice['orchestrationDetails']>
      >
    ) => {
      state.orchestrationDetails = {
        ...state.orchestrationDetails,
        ...action.payload,
      };
    },
    setSystemModules: (state, action: PayloadAction<ISystemModule[]>) => {
      return {
        ...state,
        systemModules: action.payload,
      };
    },
    setAccordionStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isAccordionOpen: action.payload,
      };
    },
    setXmlCode: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        xmlCode: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModulesVersions.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchModulesVersions.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(
      fetchModulesVersions.fulfilled,
      (state, { payload }: { payload: IModuleServer[] }) => {
        return {
          ...state,
          genericAndTenantModules: payload,
          loading: false,
        };
      }
    );
  },
});

export const selectSystemModules = (state: RootState) =>
  state.designOrchestration.systemModules;

export const selectOrchestrationDetails = (state: RootState) =>
  state.designOrchestration.orchestrationDetails;

export const selectAccordionStatus = (state: RootState) =>
  state.designOrchestration.isAccordionOpen;

export const selectModulesVersions = (state: RootState) =>
  state.designOrchestration.genericAndTenantModules;

export const selectLoading = (state: RootState) =>
  state.designOrchestration.loading;

export const selectXmlCode = (state: RootState) =>
  state.designOrchestration.xmlCode;

export const {
  clearState,
  setSystemModules,
  setOrchestrationDetails,
  setAccordionStatus,
  setXmlCode,
} = designOrchestrationSlice.actions;

export default designOrchestrationSlice.reducer;
