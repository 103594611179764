import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { StackTraceBanner } from 'components/StackTrace/StackTraceBanner/StackTraceBanner';
import { StackTraceEditor } from 'components/StackTrace/StackTraceEditor/StackTraceEditor';
import style from './StackTrace.module.scss';
import { useLocation } from 'react-router-dom';

const StackTrace = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get('jobId') || '';
  const stepId = queryParams.get('stepId') || '';

  return (
    <div data-testid="StackTrace" className={style['container']}>
      <CsbErrorBoundary>
        <StackTraceBanner jobId={jobId} stepId={stepId} />
        <StackTraceEditor
          stackTrace={`at org.alfresco.repo.bulkimport.metadataloaders.AbstractMapBasedMetadataLoader.loadMetadata(AbstractMapBasedMetadataLoader.java:123)
	at com.wolterskluwer.apollo.acs.core.bulkimport.service.ApolloDirectoryAnalyserImpl.isMetadataValid(ApolloDirectoryAnalyserImpl.java:214)
	at com.wolterskluwer.apollo.acs.core.bulkimport.service.ApolloDirectoryAnalyserImpl.analyseDirectory(ApolloDirectoryAnalyserImpl.java:134)
	at org.alfresco.repo.bulkimport.impl.AbstractFilesystemTracker.getImportableItemsInDirectory(AbstractFilesystemTracker.java:64)
	at org.alfresco.repo.bulkimport.impl.StripingFilesystemTracker.getImportableItems(StripingFilesystemTracker.java:147)
	at org.alfresco.repo.bulkimport.impl.StripingFilesystemTracker$1.getNextWork(StripingFilesystemTracker.java:209)
	at org.alfresco.repo.batch.BatchProcessor$WorkProviderIterator.hasNext(BatchProcessor.java:658)
	at org.alfresco.repo.batch.BatchProcessor.process(BatchProcessor.java:455)
	at org.alfresco.repo.batch.BatchProcessor.process(BatchProcessor.java:381)
	at org.alfresco.repo.bulkimport.impl.StripingBulkFilesystemImporter.bulkImportImpl(StripingBulkFilesystemImporter.java:65)
	at org.alfresco.repo.bulkimport.impl.AbstractBulkFilesystemImporter$3.execute(AbstractBulkFilesystemImporter.java:391)
	at org.alfresco.repo.bulkimport.impl.AbstractBulkFilesystemImporter$3.execute(AbstractBulkFilesystemImporter.java:1)
	at org.alfresco.repo.transaction.RetryingTransactionHelper.doInTransaction(RetryingTransactionHelper.java:452)
	at org.alfresco.repo.bulkimport.impl.AbstractBulkFilesystemImporter.bulkImport(AbstractBulkFilesystemImporter.java:366)
	at org.alfresco.repo.bulkimport.impl.AbstractBulkFilesystemImporter$2$1.doWork(AbstractBulkFilesystemImporter.java:347)
	at org.alfresco.repo.security.authentication.AuthenticationUtil.runAs(AuthenticationUtil.java:602)
	at org.alfresco.repo.bulkimport.impl.AbstractBulkFilesystemImporter$2.run(AbstractBulkFilesystemImporter.java:343)
	at java.base/java.lang.Thread.run(Thread.java:840)
	at org.alfresco.repo.bulkimport.metadataloaders.AbstractMapBasedMetadataLoader.loadMetadataInternal(AbstractMapBasedMetadataLoader.java:135)
	at org.alfresco.repo.bulkimport.metadataloaders.AbstractMapBasedMetadataLoader.loadMetadata(AbstractMapBasedMetadataLoader.java:118) `}
        />
      </CsbErrorBoundary>
    </div>
  );
};

export default StackTrace;
