import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './StackTraceBanner.module.scss';

interface IStackTraceBanner {
  jobId: string;
  stepId: string;
}

export const StackTraceBanner = ({ jobId, stepId }: IStackTraceBanner) => {
  return (
    <CsbErrorBoundary>
      <section className={style['container']} data-testid="StackTraceBanner">
        <span className={style['title']}>
          {jobId} Step {stepId} Stack Trace Data
        </span>
      </section>
    </CsbErrorBoundary>
  );
};
