import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModuleParameterItem } from 'interfaces/modules/module.interface';

export interface ICreateModuleState {
  moduleParameters: IModuleParameterItem[];
  selectedParameter: IModuleParameterItem | null;
  currentTab: number;
  pomContent?: string;
  manifestContent?: string;
}

export const SLICE_KEY = 'createModule';

const initialState: ICreateModuleState = {
  moduleParameters: [],
  currentTab: 0,
  selectedParameter: null,
  pomContent: undefined,
  manifestContent: undefined,
};

const createModuleSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    clearState: () => {
      return {
        moduleParameters: [],
        selectedParameter: null,
        currentTab: 0,
        pomContent: undefined,
        manifestContent: undefined,
      };
    },
    addParameter: (state, action: PayloadAction<IModuleParameterItem>) => {
      const newParameter = {
        ...action.payload,
        isRequired:
          action.payload.isRequired !== undefined
            ? action.payload.isRequired
            : true,
        value:
          action.payload.type === 'BOOLEAN'
            ? action.payload.value !== undefined
              ? action.payload.value
              : 'true' // Initialize BOOLEAN value to 'true' as a string
            : action.payload.value,
      };
      return {
        ...state,
        moduleParameters: [...state.moduleParameters, newParameter],
        selectedParameter: action.payload,
      };
    },
    selectParameter: (
      state,
      action: PayloadAction<IModuleParameterItem | null>
    ) => {
      return {
        ...state,
        selectedParameter: action.payload,
      };
    },
    setParameterList: (
      state,
      action: PayloadAction<IModuleParameterItem[]>
    ) => {
      return {
        ...state,
        moduleParameters: action.payload,
      };
    },
    updateParameterProps: (
      state,
      action: PayloadAction<{ id: string; newSelected: IModuleParameterItem }>
    ) => {
      const { id, newSelected } = action.payload;
      return {
        ...state,
        moduleParameters: state.moduleParameters.map((item) => {
          if (item.id === id) {
            return {
              ...newSelected,
              isRequired:
                newSelected.isRequired === undefined
                  ? true
                  : newSelected.isRequired,
              value:
                newSelected.type === 'BOOLEAN'
                  ? newSelected.value !== undefined
                    ? newSelected.value
                    : 'true'
                  : newSelected.value,
            };
          }
          return item;
        }),
        selectedParameter: {
          ...newSelected,
        },
      };
    },
    deleteParameter: (
      state,
      action: PayloadAction<{ id: string; param: IModuleParameterItem }>
    ) => {
      const paramIndex = state.moduleParameters.findIndex(
        (item) => item.id === action.payload.id
      );
      const nextSelectedParamIndex =
        paramIndex === 0 ? paramIndex + 1 : paramIndex - 1;
      const paramList = state.moduleParameters.map((item) => {
        if (
          action.payload.param?.paramId &&
          action.payload.param.paramId === item.dependsOn
        ) {
          return { ...item, dependsOn: '', enumValue: '' };
        } else {
          return { ...item };
        }
      });

      return {
        ...state,
        moduleParameters: paramList.filter(
          (filterItem) => filterItem.id !== action.payload.id
        ),
        selectedParameter: paramList?.[nextSelectedParamIndex] || null,
      };
    },
    setPomContent: (state, action: PayloadAction<string>) => {
      state.pomContent = action.payload;
    },
    setManifestContent: (state, action: PayloadAction<string>) => {
      state.manifestContent = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<number>) => {
      state.currentTab = action.payload;
    },
  },
});

export const selectSelectedParameter = (state: any) =>
  state.createModule.selectedParameter;

export const selectParameterList = (state: any) =>
  state.createModule.moduleParameters;
export const selectPomContent = (state: any) => state.createModule.pomContent;
export const selectManifestContent = (state: any) =>
  state.createModule.manifestContent;
export const selectCurrentTab = (state: any) => state.createModule.currentTab;

export const {
  clearState,
  addParameter,
  setParameterList,
  updateParameterProps,
  selectParameter,
  deleteParameter,
  setPomContent,
  setManifestContent,
  setCurrentTab,
} = createModuleSlice.actions;

export default createModuleSlice.reducer;
