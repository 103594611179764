import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './CellLogMessage.module.scss';
import { ILogRow } from 'interfaces/logRow.interface';

interface ICellLogMessage {
  data: ILogRow;
}

export const CellLogMessage = ({ data }: ICellLogMessage) => {
  const handleOpenStackTrace = () => {
    window.open(
      `/stack-trace?jobId=${data.metadata?.CSBJobId || ''}&stepId=${
        data.metadata?.CSBJobStepId || ''
      }`,
      '_blank'
    );
  };

  return (
    <CsbErrorBoundary>
      <div className={style['cell-container']} data-testid="CellLogMessage">
        {data?.message && (
          <span className={style['cell-message']}> {data?.message}</span>
        )}
        {data?.stackTrace && (
          <span
            className={style['cell-link']}
            data-testid="CellLogMessageLink"
            onClick={() => handleOpenStackTrace()}
          >
            View Stack Trace Data
          </span>
        )}
      </div>
    </CsbErrorBoundary>
  );
};
