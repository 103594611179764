import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  combineModuleVersions,
  transformModuleServersResponse,
} from 'dto/modules/deployModules';
import {
  IAvailableModuleServer,
  IDeployModuleSearchResponse,
  IDeployModuleServersResponse,
  IModuleServer,
} from 'interfaces/modules/module.interface';
import { handleThunkError } from 'utils/thunkErrorHandler';
import JSZip from 'jszip';

const BASE_URL = '/csb/modules';

export interface IModifyModule {
  moduleId: number;
  hostname: string;
}

export interface IModifyAllModuleHosts {
  moduleId: number;
  slave: string;
}

export interface IDeployModule {
  artifactId: string;
  version: string;
  hostname: string;
}

export interface IfetchModulesCandidates {
  artifactId: string;
  version?: string;
}

export interface IDeployModuleReq {
  selectedModule: string;
  selectedVersion: string;
  selectedServers: string[];
}

export const fetchAvailableModules = createAsyncThunk(
  'manageModules/fetchAvailableModules',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IAvailableModuleServer>(
        `${BASE_URL}/available`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const fetchAvailableModulesWithoutLoading = createAsyncThunk(
  'manageModules/fetchAvailableModulesWithoutLoading',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IAvailableModuleServer>(
        `${BASE_URL}/available`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const fetchModulesCandidates = createAsyncThunk(
  'manageModules/fetchModulesCandidates',
  async (props: IfetchModulesCandidates, { rejectWithValue }) => {
    try {
      const response = await axios.get<IDeployModuleSearchResponse[]>(
        `${BASE_URL}/candidates/${props.artifactId}?version=${props.version}`,
        {
          withCredentials: true,
        }
      );
      return combineModuleVersions(response.data);
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const fetchEC2Servers = createAsyncThunk(
  'manageModules/fetchEC2Servers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IDeployModuleServersResponse>(
        `${BASE_URL}/available/servers`,
        {
          withCredentials: true,
        }
      );
      return transformModuleServersResponse(response.data);
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const deployModule = createAsyncThunk(
  'manageModules/deployModule',
  async (data: IDeployModuleReq, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/deploy/${data.selectedModule}/${
          data.selectedVersion
        }/${data.selectedServers.join(',')}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const startModule = createAsyncThunk(
  'manageModules/startModule',
  async ({ moduleId, hostname }: IModifyModule, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/start/${moduleId}/${hostname}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const stopModule = createAsyncThunk(
  'manageModules/stopModule',
  async ({ moduleId, hostname }: IModifyModule, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/stop/${moduleId}/${hostname}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const restartModule = createAsyncThunk(
  'manageModules/restartModule',
  async ({ moduleId, hostname }: IModifyModule, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/restart/${moduleId}/${hostname}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const removeModule = createAsyncThunk(
  'manageModules/removeModule',
  async ({ moduleId, hostname }: IModifyModule, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/remove/${moduleId}/${hostname}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const updateModule = createAsyncThunk(
  'manageModules/updateModule',
  async ({ moduleId, hostname }: IModifyModule, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/update/${moduleId}/${hostname}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const startAllModules = createAsyncThunk(
  'manageModules/startAllModules',
  async ({ moduleId, slave }: IModifyAllModuleHosts, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/start/all/${moduleId}/${slave}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const stopAllModules = createAsyncThunk(
  'manageModules/stopAllModules',
  async ({ moduleId, slave }: IModifyAllModuleHosts, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/stop/all/${moduleId}/${slave}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const restartAllModules = createAsyncThunk(
  'manageModules/restartAllModules',
  async ({ moduleId, slave }: IModifyAllModuleHosts, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/restart/all/${moduleId}/${slave}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const removeAllModules = createAsyncThunk(
  'manageModules/removeAllModules',
  async ({ moduleId, slave }: IModifyAllModuleHosts, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/remove/all/${moduleId}/${slave}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const updateAllModules = createAsyncThunk(
  'manageModules/updateAllModules',
  async ({ moduleId, slave }: IModifyAllModuleHosts, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/update/all/${moduleId}/${slave}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const deployModuleThunk = createAsyncThunk(
  'manageModules/deployModuleThunk',
  async (
    { artifactId, version, hostname }: IDeployModule,
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/deploy/${artifactId}/${version}/${hostname}`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const generateModuleBlob = createAsyncThunk(
  'manageModules/generateModuleBlob',
  async (modulePayload: any, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/generate?module=${encodeURIComponent(
          JSON.stringify(modulePayload)
        )}`,
        { withCredentials: true, responseType: 'arraybuffer' }
      );

      // Process the ArrayBuffer within the thunk
      const zip = await JSZip.loadAsync(response.data);
      const textId = modulePayload.textId ?? modulePayload.artifactId ?? '';

      const pomFile = zip.file(`csb-modules-${textId}/pom.xml`);
      const manifestFile = zip.file(`csb-modules-${textId}/manifest.xml`);

      if (pomFile && manifestFile) {
        const pomContent = await pomFile.async('string');
        const manifestContent = await manifestFile.async('string');

        // Return serializable content
        return { pomContent, manifestContent };
      } else {
        return handleThunkError(
          'Required files not found in the ZIP archive.',
          rejectWithValue
        );
      }
    } catch (error: any) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const generateModule = createAsyncThunk(
  'manageModules/generateModule',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/generate?module=${encodeURIComponent(
          JSON.stringify(data)
        )}`,
        { withCredentials: true, responseType: 'arraybuffer' }
      );

      const contentDisposition =
        response.headers['content-disposition'] || 'download.zip';
      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });

      return new Promise<{
        data: string | ArrayBuffer | null;
        filename: string;
        contentType: string;
      }>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve({
            data: base64data,
            filename: contentDisposition
              .split('filename=')[1]
              ?.replace(/["']/g, ''),
            contentType,
          });
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const fetchModulesVersions = createAsyncThunk(
  'manageModules/fetchModulesVersions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IModuleServer[]>(`${BASE_URL}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);
