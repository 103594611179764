import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import AceEditor from 'react-ace';
import ace from 'ace-builds';

interface IStackTraceEditor {
  stackTrace: string;
}

export const StackTraceEditor = ({ stackTrace }: IStackTraceEditor) => {
  return (
    <CsbErrorBoundary>
      <div data-testid="StackTraceEditor">
        <AceEditor
          mode="text"
          theme=""
          name="editor"
          width="100%"
          value={stackTrace}
          height="100vh"
          showPrintMargin={false}
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false, wrap: true }}
          onLoad={(editor) => {
            editor.session.setFoldStyle('markbegin');
            const Range = ace.require('ace/range').Range;
            editor.session.addFold('Error stack trace', new Range(4, 0, 20, 0));
          }}
        />
      </div>
    </CsbErrorBoundary>
  );
};
