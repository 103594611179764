import NotificationBox from 'components/NotificationBox/NotificationBox';
import {
  NOTIFICATION_BOX_CONTENT,
  NOTIFICATION_BOX_MESSAGES,
} from 'utils/common-constants';

interface IAuthErrorNotification {
  authError?: NOTIFICATION_BOX_MESSAGES;
  isLoading: boolean;
  handleLogout: () => void;
}

const AuthErrorNotification = ({
  authError,
  isLoading,
  handleLogout,
}: IAuthErrorNotification) => {
  if (authError && !isLoading && NOTIFICATION_BOX_CONTENT[authError]) {
    return (
      <NotificationBox
        notificationContent={NOTIFICATION_BOX_CONTENT[authError]}
        onCta={handleLogout}
      />
    );
  }

  return <></>;
};

export default AuthErrorNotification;
