export const useStackTrace = () => {
  const isStackTraceLine = (line: string): boolean => {
    const stackTraceRegex = /^\s*at\s+(.*?)\s*\(.*\)$/;
    return stackTraceRegex.test(line.trim());
  };

  const containsStackTrace = (message: string): boolean => {
    const lines = message.split('\n');
    return lines.some(isStackTraceLine);
  };

  const flagStackTrace = (logRowObject: any[]) => {
    logRowObject.forEach((logRow: any) => {
      if (logRow.message && containsStackTrace(logRow.message)) {
        logRow.stackTrace = extractStackTrace(logRow.message).stackTrace;
        logRow.message = '';
      }
    });
  };

  const extractStackTrace = (message: string): any | null => {
    const lines = message.split('\n');
    let stackTrace = '';
    let errorMessage = '';

    const stackTraceLines = lines.filter(isStackTraceLine);

    const nonStackTraceLines = lines.filter(
      (line) => !isStackTraceLine(line)
    )[0];

    if (nonStackTraceLines.length > 0) {
      errorMessage = nonStackTraceLines;
    }

    if (stackTraceLines.length > 0) {
      stackTrace = stackTraceLines.join('\n');
    }

    if (stackTrace || errorMessage) {
      return { stackTrace, errorMessage };
    }

    return null;
  };

  return {
    flagStackTrace,
    containsStackTrace,
    isStackTraceLine,
  };
};
