import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { OverlayComponent } from 'components/OverlayComponent/OverlayComponent';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import React, { Suspense, useEffect, useState } from 'react';
import { retryLazyLoad } from 'utils/commonFunctions/CommonFunctions';

const DesignOrchestrationLazy = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => {
  const [Component, setComponent] =
    useState<React.LazyExoticComponent<any> | null>(null);
  const [loadError, setLoadError] = useState<boolean>(false);

  useEffect(() => {
    retryLazyLoad(() => import('./DesignOrchestration'), 4, 15000)
      .then((module: any) =>
        setComponent(() => module.default || module.RunOrchestrations)
      )
      .catch(() => setLoadError(true));
  }, []);

  if (loadError) {
    return <div>Error loading, please reload the page.</div>;
  }

  if (!Component) {
    return <OverlayComponent page={PAGE_OPTIONS.generalPage} />;
  }

  return (
    <CsbErrorBoundary>
      <Suspense fallback={<OverlayComponent page={PAGE_OPTIONS.generalPage} />}>
        <Component {...props} />
      </Suspense>
    </CsbErrorBoundary>
  );
};

export default DesignOrchestrationLazy;
