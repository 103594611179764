import { ILogFromServer, ILogRow } from 'interfaces/logRow.interface';
import { IOrchestrationSidePanelSteps } from 'interfaces/orchestrationDiagram/orchestrationDiagram.interface';
import { DateUtils } from 'utils/dateUtils/DateUtils';

export const objectFromLogData = (
  log: ILogFromServer[],
  steps?: IOrchestrationSidePanelSteps[]
): ILogRow[] => {
  return log.map((item: ILogFromServer) => {
    return {
      createdTime: DateUtils.formatDateFromUtcString(item.timestamp) ?? '-',
      severity: item.logLevel ?? 'INFO',
      ['Step name']: item.metadata?.CSBWorkflowStepName
        ? `${getStepIndex(steps, item.metadata.CSBJobStepId)}  ${
            item.metadata?.CSBWorkflowStepName
          }`
        : 'No Step name available',
      message: item.message ?? '-',
      metadata: {
        CSBJobId: item.metadata?.CSBJobId ?? '',
        CSBJobStepId: item.metadata?.CSBJobStepId ?? '',
      },
    } as ILogRow;
  });
};

const getStepIndex = (
  steps?: IOrchestrationSidePanelSteps[],
  jobStepId?: string
): string => {
  const currentStep = steps?.find(
    (instanceStep) => instanceStep.jobStepId === Number(jobStepId)
  )?.index;
  if (currentStep) {
    return currentStep;
  }
  return '';
};
