import { useState, useEffect } from 'react';

const useDynamicHeight = () => {
  const [heightOffset, setHeightOffset] = useState(85);

  useEffect(() => {
    const updateHeightOffset = () => {
      const screenHeight = window.innerHeight;
      const baseHeight = 85;
      const referenceHeight = 1080;
      const reductionFactor = Math.floor(
        ((screenHeight - referenceHeight) / 100) * 2
      );
      let newHeightOffset = baseHeight + reductionFactor;
      if (newHeightOffset > 90) {
        newHeightOffset = 90;
      }
      setHeightOffset(newHeightOffset);
    };

    updateHeightOffset();
    window.addEventListener('resize', updateHeightOffset);

    return () => {
      window.removeEventListener('resize', updateHeightOffset);
    };
  }, []);

  return heightOffset;
};

export default useDynamicHeight;
