import { orchestrationApi } from '../api/orchestrations/orchestrationApi';
import {
  Action,
  configureStore,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';
import authReducer, { SLICE_KEY as authSliceKey } from './auth/AuthSlice';
import toastReducer, { SLICE_KEY as toastSliceKey } from './toast/toastSlice';
import runOrchestrationsReducer, {
  SLICE_KEY as runOrchestrationsSliceKey,
} from './runOrchestrations/RunOrchestrationsSlice';
import dashboardReducer, {
  SLICE_KEY as dashboardSliceKey,
} from './dashboard/DashboardSlice';
import modalReducer, { SLICE_KEY as modalSliceKey } from './modal/ModalSlice';
import manageModulesReducer, {
  SLICE_KEY as manageModulesSliceKey,
} from './manageModules/ManageModulesSlice';
import createModuleReducer, {
  SLICE_KEY as createModuleSliceKey,
} from './createModule/CreateModuleSlice';
import propertiesPanelReducer, {
  SLICE_KEY as propertiesPanelSliceKey,
} from './propertiesPanel/PropertiesPanelSlice';
import designOrchestrationReducer, {
  SLICE_KEY as designOrchestrationSliceKey,
} from './designOrchestration/DesignOrchestrationSlice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { authApi } from 'api/auth/authApi';
import { workflowsApi } from 'api/workflows/workflows';
import { modulesApi } from 'api/manageModules/modulesApi';

export const store = configureStore({
  reducer: {
    //slices
    [authSliceKey]: authReducer,
    [dashboardSliceKey]: dashboardReducer,
    [modalSliceKey]: modalReducer,
    [toastSliceKey]: toastReducer,
    [propertiesPanelSliceKey]: propertiesPanelReducer,
    [runOrchestrationsSliceKey]: runOrchestrationsReducer,
    [manageModulesSliceKey]: manageModulesReducer,
    [createModuleSliceKey]: createModuleReducer,
    [designOrchestrationSliceKey]: designOrchestrationReducer,
    //apis
    [orchestrationApi.reducerPath]: orchestrationApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [workflowsApi.reducerPath]: workflowsApi.reducer,
    [modulesApi.reducerPath]: modulesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(orchestrationApi.middleware)
      .concat(authApi.middleware)
      .concat(modulesApi.middleware),
  devTools: {
    serialize: true,
  },
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: store.getState,
    preloadedState,
  });
}

setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
