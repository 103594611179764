import style from './CreateModuleSourceTab.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { SegmentedControl, SegmentedControlItem } from '@wk/components-react16';
import ManifestSourceCode from './components/ManifestSourceCode/ManifestSourceCode';
import PomSourceCode from './components/PomSourceCode/PomSourceCode';
import { useState } from 'react';

const CreateModuleSourceTab = () => {
  const [activeTabId, setActiveTabId] = useState(0);

  return (
    <CsbErrorBoundary>
      <div data-testid="CreateModuleSourceTab" className={style['container']}>
        <SegmentedControl
          onUserRequest={(selectedTab: number) => {
            setActiveTabId(selectedTab);
          }}
        >
          <SegmentedControlItem slot="segmentedControlItem">
            manifest.xml
          </SegmentedControlItem>
          <SegmentedControlItem slot="segmentedControlItem">
            pom.xml
          </SegmentedControlItem>
        </SegmentedControl>
        <div className={style['editor-container']}>
          {activeTabId === 0 && <ManifestSourceCode />}
          {activeTabId === 1 && <PomSourceCode />}
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default CreateModuleSourceTab;
