import { NotificationContent } from 'components/NotificationBox/NotificationBox';
import { LogSeverities } from 'interfaces/logRow.interface';

export enum HTTP_STATUS_CODES {
  SUCCESS = 200,
  CREATED = 201,
  INTERNAL_SERVER_ERROR = 500,
  UNAUTHORIZED = 401,
}

export enum STEP_STATUSES {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  FINISHED_ERROR = 'FINISHED_ERROR',
  FAILED = 'FAILED',
  QUEUED = 'QUEUED',
}

export enum ORCHESTRATION_STATUSES {
  FINISHED = 'FINISHED',
  FINISHED_ERROR = 'FINISHED_ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  STOPPED = 'STOPPED',
  STOPPING = 'STOPPING',
}

export enum ORCHESTRATION_FILTERS {
  ANY = 'ANY',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  STOPPED = 'STOPPED',
}

export const MAP_STATUS_ICON = {
  [ORCHESTRATION_STATUSES.FINISHED]: {
    name: 'check-circle',
    color: '#85BC20',
    size: 20,
    description: 'Orchestration finished or finished with errors',
  },
  [ORCHESTRATION_STATUSES.FINISHED_ERROR]: {
    name: 'check-circle',
    color: '#85BC20',
    size: 20,
    description: 'Orchestration finished or finished with errors',
  },
  [ORCHESTRATION_STATUSES.PENDING]: {
    name: 'infinity',
    color: 'black',
    size: 20,
    description: 'Orchestration pending',
  },
  [ORCHESTRATION_STATUSES.IN_PROGRESS]: {
    name: 'clock',
    color: '#EA8F00',
    size: 20,
    description: 'Orchestration in progress',
  },
  [ORCHESTRATION_STATUSES.FAILED]: {
    name: 'close-circle',
    color: '#E5202E',
    size: 20,
    description: 'Orchestration failed',
  },
  [ORCHESTRATION_STATUSES.STOPPED]: {
    name: 'stop-sign',
    color: '#721017',
    size: 20,
    description: 'Orchestration stopping or stopped',
  },
  [ORCHESTRATION_STATUSES.STOPPING]: {
    name: 'stop-sign',
    color: '#721017',
    size: 20,
    description: 'Orchestration stopping or stopped',
  },
};

export enum JOBS_OWNER_FILTER {
  ALL_JOBS = 'ALL_JOBS',
  USER_JOBS = 'USER_JOBS',
}

export enum ENVIROMENTS {
  NA_STAGE = 'NA_STAGE',
  NA_PROD = 'NA_PROD',
  EU_STAGE = 'EU_STAGE',
  EU_PROD = 'EU_PROD',
  DEV_ENV = 'DEV_ENV',
}

export const generateSeverityStatusIcon = (severity: LogSeverities) => {
  const severityMap = {
    ['ERROR']: {
      name: 'close-circle',
      color: 'red',
      size: 20,
      description: 'Step Eror',
    },
    ['WARN']: {
      name: 'caution',
      color: 'black',
      size: 20,
      description: 'Step warning',
    },
    ['DEBUG']: {
      name: 'code',
      color: 'black',
      size: 20,
      description: 'Step debug',
    },
    ['INFO']: {
      name: 'info',
      color: '#black',
      size: 20,
      description: 'Step info',
    },
  };
  return severityMap[severity];
};

export enum USER_PERMISSIONS {
  DESIGNER_PAGE = 'DESIGNER_PAGE',
  MODULES_PAGE = 'MODULES_PAGE',
  MODULE_CLONE = 'MODULE_CLONE',
  MODULE_CREATE_PAGE = 'MODULE_CREATE_PAGE',
  MODULE_DEPLOY = 'MODULE_DEPLOY',
  MODULE_EDIT_CONFIG = 'MODULE_EDIT_CONFIG',
  MODULE_REMOVE = 'MODULE_REMOVE',
  MODULE_RESTART = 'MODULE_RESTART',
  MODULE_START = 'MODULE_START',
  MODULE_STOP = 'MODULE_STOP',
  MODULE_UPDATE = 'MODULE_UPDATE',
  ORCHESTRATIONS_RUN_HISTORY_PAGE = 'ORCHESTRATIONS_RUN_HISTORY_PAGE',
  ORCHESTRATION_CREATE = 'ORCHESTRATION_CREATE',
  ORCHESTRATION_DEACTIVATE = 'ORCHESTRATION_DEACTIVATE',
  ORCHESTRATION_EDIT = 'ORCHESTRATION_EDIT',
  ORCHESTRATION_EXPORT = 'ORCHESTRATION_EXPORT',
  ORCHESTRATION_IMPORT = 'ORCHESTRATION_IMPORT',
  ORCHESTRATION_REMOVE = 'ORCHESTRATION_REMOVE',
  ORCHESTRATION_RUN_PAGE = 'ORCHESTRATION_RUN_PAGE',
  ORCHESTRATION_STOP = 'ORCHESTRATION_STOP',
  ORCHESTRATION_SUBMIT = 'ORCHESTRATION_SUBMIT',
  ORCHESTRATION_VIEW_ALL = 'ORCHESTRATION_VIEW_ALL',
  PRODUCT_CREATE = 'PRODUCT_CREATE',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  PRODUCT_REMOVE = 'PRODUCT_REMOVE',
  SERVERS_PAGE = 'SERVERS_PAGE',
  SERVER_ADD = 'SERVER_ADD',
  SERVER_EDIT = 'SERVER_EDIT',
  SERVER_REMOVE = 'SERVER_REMOVE',
  SERVICE_NOW_CREATE = 'SERVICE_NOW_CREATE',
}

export enum SSE_EVENTS {
  JOB_COUNTERS_UPDATE = 'JOB_COUNTERS_UPDATE',
  JOB_STATUS_UPDATE = 'JOB_STATUS_UPDATE',
  JOB_STEP_UPDATE = 'JOB_STEP_UPDATE',
  JOB_CREATED = 'JOB_CREATED',
  ORCHESTRATION_CREATED = 'ORCHESTRATION_CREATED',
  ORCHESTRATION_UPDATED = 'ORCHESTRATION_UPDATED',
  ORCHESTRATION_DELETED = 'ORCHESTRATION_DELETED',
}

export enum SSE_STATUS {
  SUCCESS = 'SUCCESS',
  RESTORED = 'RESTORED',
  ERROR = 'ERROR',
}

export enum TABLE_FIELDS {
  id = 'id',
  name = 'name',
  startTime = 'startTime',
  endTime = 'endTime',
  duration = 'duration',
  user = 'user.name',
  status = 'status',
  step = 'step',
  originalFileName = 'originalFileName',
  //run orchestration
  description = 'description',
  worker = 'worker',
  throttleRate = 'throttleRate',
  options = 'options',
  // error-log modal fields
  createdTime = 'createdTime',
  message = 'message',
  severity = 'severity',
  //modules
  version = 'version',
  workers = 'workers',
  consumers = 'consumers',
  totalCapacity = 'totalCapacity',
  // module details
  type = 'type',
  required = 'required',
  default = 'default',
}

export const TABLE_FILTERS = [
  'jobId',
  'beforeStartDate',
  'afterEndDate',
  'durationStart',
  'durationEnd',
  'workflowNames',
  'userIds',
  'startDate',
  'endDate',
  'startDateTo',
  'endDateTo',
  'fileName',
];

export enum PAGE {
  ORCHESTRATION_STATUS = 'Orchestration status',
  ORCHESTRATION_DETAILS = 'Details',
  RUN_ORCHESTRATION = 'Run orchestration',
  MANAGE_MODULES = 'Manage modules',
  DESIGN_ORCHESTRATION = 'Design orchestration',
}

export enum USERS_ROLES {
  DEVELOPER = 'Developer',
  ADMIN = 'Admin',
  USER = 'User',
}

export const history = {
  navigate: undefined,
  location: undefined,
};

export enum SPINE_MENU_ITEMS {
  VIEW_ORCHESTRATION = '1',
  RUN_ORCHESTRATION = '2',
  EDIT_ORCHESTRATION = '3',
  MANAGE_MODULES = '4',
}

export const MAX_INTEGER_CSB = 2147483647;
export const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred';

export enum NOTIFICATION_TYPES {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum NOTIFICATION_BOX_MESSAGES {
  AUTH_ERROR = 'AUTH_ERROR',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  BUA_ERROR = 'BUA_ERROR',
}

export const NOTIFICATION_BOX_CONTENT: Record<
  NOTIFICATION_BOX_MESSAGES,
  NotificationContent
> = {
  [NOTIFICATION_BOX_MESSAGES.AUTH_ERROR]: {
    type: NOTIFICATION_TYPES.ERROR,
    header: 'Access Denied',
    textContent:
      'CSB UI cannot access the BU Administrator Tool or the Authenticated user is not registered in the BU Administrator Tool',
    ctaText: 'Go to the Login page',
  },
  [NOTIFICATION_BOX_MESSAGES.SERVICE_UNAVAILABLE]: {
    type: NOTIFICATION_TYPES.ERROR,
    header: 'Service Unavailable',
    textContent: 'The service is currently unavailable. Please try again later',
  },
  [NOTIFICATION_BOX_MESSAGES.BUA_ERROR]: {
    type: NOTIFICATION_TYPES.ERROR,
    header: 'BUA Error',
    textContent: 'Error from checkBua service',
    ctaText: 'Go to the Login page',
  },
};

export enum CREATE_MODULE_TABS_IDS {
  NORMAL_VIEW = 'NORMAL_VIEW',
  SOURCE_VIEW = 'SOURCE_VIEW',
}

export const CREATE_MODULE_TABS = [
  { id: CREATE_MODULE_TABS_IDS.NORMAL_VIEW, label: 'Normal view' },
  { id: CREATE_MODULE_TABS_IDS.SOURCE_VIEW, label: 'Source view' },
];

export const MODULE_PARAMETER_TYPES = [
  {
    value: 'STRING',
    label: 'String',
    description:
      'This parameter type is a set of numbers, letters, or symbols.',
  },
  {
    value: 'SECURE',
    label: 'Secure String',
    description:
      'This parameter type is used for secret information like passwords and keys. It stores the same information as String but is hidden on the CSB UI and excluded from log data.',
  },
  {
    value: 'INTEGER',
    label: 'Integer',
    description: 'This parameter type stores integer values only.',
  },
  {
    value: 'BOOLEAN',
    label: 'Boolean',
    description:
      'This parameter type represents two values, usually true and false, to represent the two values of logic.',
  },
  {
    value: 'ENUM',
    label: 'Enum',
    description:
      'This parameter type supports a predefined set of values entered in the value field.',
  },
  {
    value: 'MULTI',
    label: 'Multi',
    description:
      'This parameter type supports a predefined set of values entered in the value field. You can select/deselect one or more of these values at a time using the checkboxes.',
  },
];

export const CREATE_MODULE_DEFAULT_PARAMETER_NAME = 'New parameter name';
export const DELETE_PARAMETER_MESSAGE =
  'Are you sure you want to delete this parameter? Other parameters that depend on the parameter you are deleting will become independent after you delete this parameter. Dependent parameters are listed below where they exist.';
export const SUCCESS_DELETE_PARAMETER_MESSAGE =
  'The selected parameter has been deleted and all previously dependent parameters are now independent.';
export const SUCCESS_MODULE_CREATED = 'Module created successfully';
export const FAIL_MODULE_CREATED = 'Module not created. Please try again';
