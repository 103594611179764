import { useLazyCheckBuaQuery } from 'api/auth/authApi';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { showToastAndClose } from 'utils/commonFunctions/CommonFunctions';

export function useCheckBua() {
  const dispatch = useAppDispatch();
  const DEFAULT_BUA_ERROR = 'BUA service error';
  const [checkBua] = useLazyCheckBuaQuery();
  const fetchCheckBua = async () => {
    const { data, error } = await checkBua({});

    if (error) {
      showToastAndClose('error', 'informationToast', () => {}, dispatch, {
        text: DEFAULT_BUA_ERROR,
      });
    }

    if (data) {
      showToastAndClose('error', 'informationToast', () => {}, dispatch, {
        text: data,
      });
    }
  };

  useEffect(() => {
    fetchCheckBua();
    const interval = setInterval(fetchCheckBua, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);
}
