import {
  ColDef,
  ColumnApi,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowDataUpdatedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  selectFilterModel,
  selectFilterStatus,
  selectPaginationInfo,
} from '../../../redux/dashboard/DashboardSlice';
import ErrorLogCellStatus from 'components/ErrorLogCellStatus/ErrorLogCellStatus';
import { ITableContainerProps } from 'interfaces/table.interface';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './LogTable.module.scss';
import { setNameFilterPopupWidth } from '../utils/filterUtils';
import {
  handleFilter,
  handleRowDataUpdated,
  handleSort,
} from '../utils/eventsHandlers';
import DateTimeInput from 'components/DateTimeInput/DateTimeInput';
import { IJobStartedByUser } from 'interfaces/user.interface';
import { OverlayComponent } from 'components/OverlayComponent/OverlayComponent';
import { CellLogMessage } from 'components/CellLogMessage/CellLogMessage';

const defaultColumnDef: ColDef = {
  width: 200,
  sortable: true,
  suppressColumnsToolPanel: true,
  icons: {
    sortAscending: '<span class="wk-icon-arrow-up" aria-hidden="true"/></span>',
    sortDescending:
      '<span class="wk-icon-arrow-down" aria-hidden="true"/></span>',
  },
};

const gridOptionComponents = {
  errorLogCellStatus: ErrorLogCellStatus,
  cellLogMessage: CellLogMessage,
  agDateInput: DateTimeInput,
};

export const LogTable = (props: ITableContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const usersRef = useRef<IJobStartedByUser[]>([]);
  const paginatorInfo = useAppSelector(selectPaginationInfo);
  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);

  const { loading, activeFilters: activeFiltersState } = useAppSelector(
    (state) => state.dashboardContainer
  );
  const activeFilters = useRef(activeFiltersState);
  const filtersState = useAppSelector(selectFilterStatus);
  const filterModelState = useAppSelector(selectFilterModel);
  const filterModel = useRef(filterModelState);
  const filterInfo: any = useRef(filtersState);
  const openFilterId: any = useRef();
  const filterDomElement: any = useRef(null);

  const getLoading = useCallback(() => {
    if (gridApiRef.current == null) {
      setTimeout(() => {
        getLoading();
      }, 100);
    } else {
      loading
        ? gridApiRef.current?.showLoadingOverlay()
        : gridApiRef.current?.hideOverlay();
    }
  }, [loading, gridApiRef]);

  useEffect(() => {
    filterInfo.current = filtersState;
  }, [filtersState]);

  useEffect(() => {
    activeFilters.current = activeFiltersState;
  }, [activeFiltersState]);

  useEffect(() => {
    filterModel.current = filterModelState;
  }, [filterModelState]);

  useEffect(() => {
    getLoading();
    adjustColumnsWidth();
  }, [getLoading, props.items]);

  const filterDomWindowObserver = new MutationObserver((mutations) => {
    if (!document.body.contains(filterDomElement.current)) {
      const activeFilterInstance = gridOptions.api?.getFilterInstance(
        openFilterId.current
      );
      if (activeFilterInstance?.isFilterActive() === true) {
        gridOptions.api?.setFilterModel(filterModel.current);
      } else {
        activeFilterInstance?.setModel(null);
      }
      gridOptions.api?.onFilterChanged();
      filterDomElement.current = null;
      filterDomWindowObserver.disconnect();
    }
  });

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;

    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const onFilterOpened = (event: any) => {
    filterDomElement.current = document.querySelector(
      '.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child'
    );
    if (filterDomElement?.current) {
      filterDomWindowObserver.observe(filterDomElement.current.parentNode, {
        childList: true,
        subtree: true,
      });
      const id = event.column.colId;
      openFilterId.current = id;
      setNameFilterPopupWidth(id);
    }
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    handleFilter(
      event,
      filterModel,
      false,
      filterInfo.current,
      usersRef,
      dispatch,
      paginatorInfo
    );
  };

  const onSortChanged = (params: any) => {};

  const onRowDataUpdated = (event: RowDataUpdatedEvent) => {
    handleRowDataUpdated(
      event,
      gridColumnApiRef,
      gridApiRef,
      activeFilters,
      props.onGridReadyAdditionalMethods
    );
  };

  const adjustColumnsWidth = () => {
    setTimeout(() => {
      gridColumnApiRef.current?.autoSizeAllColumns();
      gridApiRef.current?.sizeColumnsToFit();
    }, 200);
  };

  const gridOptions: GridOptions = {
    components: gridOptionComponents,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    localeText: {
      search: 'Start typing...',
    },
    rowMultiSelectWithClick: true,
    defaultColDef: defaultColumnDef,
    headerHeight: 40,
    rowHeight: 40,
    domLayout: 'autoHeight',
    loadingOverlayComponent: OverlayComponent,
    loadingOverlayComponentParams: { page: props.page, border: true },
    overlayNoRowsTemplate: '<span>No results</span>',
    animateRows: true,
    onGridReady,
    onSortChanged,
    onFilterChanged,
    onGridSizeChanged,
    onFilterOpened,
    onRowDataUpdated,
  };

  return (
    <CsbErrorBoundary>
      <div className={style['csb-table']}>
        <div
          className={`${style['csb-table-container']} wk-advanced-table-container`}
          data-testid="TableContainer"
        >
          <div
            className={`wk-advanced-table ${style['csb-table-content']} ${
              props.additionalStyles ?? style['wk-striped']
            }`}
          >
            <div className={style['csb-table-top-border']}></div>
            <AgGridReact
              gridOptions={gridOptions}
              rowData={props.items}
              showOpenedGroup={true}
              animateRows={true}
              groupMaintainOrder={false}
              {...props.additionalData}
            >
              {props.columns.map((column) => (
                <AgGridColumn {...column} key={column.field} />
              ))}
            </AgGridReact>
          </div>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};
