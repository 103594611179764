import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  Iconsvg,
} from '@wk/components-react16';
import { generateModule } from 'api/manageModules/manageModulesThunks';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import saveAs from 'file-saver';
import {
  ICreateModuleData,
  ICreateModuleOverviewNames,
  IModuleParameterItem,
} from 'interfaces/modules/module.interface';
import { useCallback, useMemo, useState } from 'react';
import {
  UseFormWatch,
  FieldValues,
  UseFormReset,
  UseFormHandleSubmit,
} from 'react-hook-form';
import {
  clearState,
  selectParameterList,
  selectPomContent,
  selectManifestContent,
  selectCurrentTab,
} from 'redux/createModule/CreateModuleSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';
import {
  FAIL_MODULE_CREATED,
  SUCCESS_MODULE_CREATED,
} from 'utils/common-constants';
import {
  b64toBlob,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import {
  formatModuleData,
  parseParametersXML,
  parsePomXML,
} from 'components/Modals/utils/createModuleModal/utils';

const requiredFields: ICreateModuleOverviewNames[] = [
  'moduleName',
  'artifactId',
  'description',
  'authorName',
];

interface ICancelCreateModuleFooter {
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  handleSubmit: UseFormHandleSubmit<any>;
}

const CancelCreateModuleFooter = ({
  watch,
  reset,
  handleSubmit,
}: ICancelCreateModuleFooter) => {
  const [isLoading] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const modalInfo = useAppSelector((state) => state.modal);
  const moduleParameters = useAppSelector(
    (state) => state.createModule.moduleParameters
  );
  const dispatch = useAppDispatch();
  const parameterList = useAppSelector(
    selectParameterList
  ) as IModuleParameterItem[];
  const allFields = watch(requiredFields);
  const pomContent = useAppSelector(selectPomContent);
  const manifestContent = useAppSelector(selectManifestContent);
  const currentTab = useAppSelector(selectCurrentTab);

  const areAllFieldsFilled = useMemo(
    () =>
      requiredFields.every(
        (field, index) => allFields[index] && allFields[index].trim() !== ''
      ),
    [allFields, requiredFields]
  );

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.cancel) {
      reset();
      dispatch(clearState());
      dispatch(
        closeModal({
          id: modalInfo.id,
          action,
        })
      );
    }
  };

  const onSubmit = async (data: any) => {
    let formattedData: any;
    if (currentTab === 0) {
      const moduleData = { ...data } as ICreateModuleData;
      setIsGenerating(true);
      formattedData = formatModuleData(moduleData, moduleParameters);
    } else {
      const moduleParametersParsed = await parseParametersXML(manifestContent);
      const moduleDataParsed = {
        ...parsePomXML(pomContent),
        groupId: data['groupId'],
        email: data['email'],
      };
      if (moduleParametersParsed && moduleDataParsed) {
        formattedData = formatModuleData(
          moduleDataParsed,
          moduleParametersParsed
        );
      }
    }
    try {
      const responseData = await dispatch(
        generateModule(formattedData)
      ).unwrap();

      const b64Data = responseData?.data?.split(',')[1];
      const blob = b64toBlob(b64Data, responseData?.contentType);

      saveAs(blob, responseData?.filename);

      showToastAndClose('success', 'informationToast', () => {}, dispatch, {
        text: SUCCESS_MODULE_CREATED,
      });
    } catch (error) {
      showToastAndClose('error', 'informationToast', () => {}, dispatch, {
        text: FAIL_MODULE_CREATED,
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const checkCreateCodeStatus = useCallback(() => {
    return parameterList.some((param) => {
      const isParamIdInvalid = !param.paramId;
      const isTypeInvalid = !param.type;
      const isParamNameInvalid = !param.paramName;
      const isValueInvalid = param.type === 'ENUM' && !param.value;
      const isValuesInvalid = param.type === 'ENUM' && !param.values;
      const isEnumValueInvalid =
        (param.dependsOn && !param.enumValue) ||
        (param.enumValue && !param.dependsOn);

      return (
        isParamIdInvalid ||
        isTypeInvalid ||
        isParamNameInvalid ||
        isValueInvalid ||
        isValuesInvalid ||
        isEnumValueInvalid
      );
    });
  }, [parameterList]);

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
              data-testid="CancelButton"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'} iconPosition="left">
            <button
              type="button"
              data-testid="SubmitButton"
              id="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={
                checkCreateCodeStatus() ||
                !areAllFieldsFilled ||
                isLoading ||
                isGenerating
              }
            >
              <Iconsvg name="bolt" />
              <span>Create code</span>
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default CancelCreateModuleFooter;
